<template>
 <v-runtime-template :template="template"></v-runtime-template>
</template>
<script>
import VRuntimeTemplate from "vue3-runtime-template";
import { useRouter } from "vue-router";
import Swal from "sweetalert2";
import axios from "axios";
import $ from "jquery";
axios.defaults.withCredentials = true;
const api = axios.create({
 baseURL: window.API_URL || "/api/",
});
export default {
 name: "HeaderTemplate",
 data() {
  var res = JSON.parse(localStorage.getItem("res"));
  return {
   v: this,
   w: window,
   h: axios,
   template: res.templates.headerTemplate.content,
   res: res,
   router: useRouter(),
   intervalId: null,
   ticket: null,
  };
 },
 components: {
  VRuntimeTemplate,
 },
 watch: {
  // Watch for route changes
  $route() {
   if (
    this.$globalVariable.header_ws &&
    this.$globalVariable.header_ws !== null
   )
    this.websocketsend("view=" + this.$route.name);
   $("#header_route_reload").click();
  },
 },
 methods: {
  jQuery(selectort) {
   return $(selectort);
  },
  sumOfList(list, prop) {
   var s = 0;
   if (list && Array.isArray(list)) {
    list.forEach((element) => {
     s += element[prop];
    });
   }
   return s;
  },
  swalFire(obj) {
   if (obj.preConfirmFormID) {
    obj.preConfirm = this.preCheckForm(obj.preConfirmFormID);
   }
   Swal.fire(obj).then((result) => {
    if (obj.isConfirmed && result.isConfirmed) obj.isConfirmed(result);
   });
  },
  saveStorage(a, b) {
   this.$globalVariable[a] = b;
   localStorage.setItem(a, JSON.stringify(b));
   this.$globalVariable.ticket++;
  },
  getStorage(a) {
   if (!localStorage.getItem(a)) return this.$globalVariable[a];
   var b = localStorage.getItem(a);
   if (b) b = JSON.parse(b);
   return b;
  },
  preCheckForm(formID) {
   return new Promise((resolve, reject) => {
    setTimeout(() => {
     const inputs = document.querySelectorAll(
      "#" + formID + " input,#" + formID + " textarea,#" + formID + " select"
     );
     for (let input of inputs) {
      const pattern = input.getAttribute("pattern");
      if (pattern && !new RegExp(pattern).test(input.value)) {
       reject("驗證失败");
       return;
      }
     }
     let result = {};
     inputs.forEach((input) => {
      result[input.name] = input.value;
     });
     resolve(result);
    }, 200);
   });
  },
  submitFormJson(formID, actionUrl, succ_call, error_call) {
   var formData = {};
   $("#" + formID)
    .find(":input[name]")
    .each(function () {
     formData[this.name] = $(this).val();
    });
   axios
    .post(actionUrl, "json=" + JSON.stringify(formData), {
     headers: {
      "Content-Type": "application/x-www-form-urlencoded",
     },
    })
    .then((response) => {
     console.log(response);
     if (succ_call) succ_call();
    })
    .catch((error) => {
     console.error("Error fetching items:", error);
     if (error_call) error_call();
    });
  },
  getCmsList(apiEndpoint) {
   // 檢查 apiEndpoint 是以 '/' 還是以 '{' 開頭
   if (apiEndpoint.startsWith("ws://") || apiEndpoint.startsWith("wss://")) {
    return Promise.resolve(); // 直接返回一个解决的Promise
   }
   if (
    apiEndpoint.startsWith("http://") ||
    apiEndpoint.startsWith("https://")
   ) {
    axios.defaults.withCredentials = true;
    // 使用 axios 直接请求，而不使用通过 axios.create 创建的实例
    return axios
     .get(apiEndpoint)
     .then((response) => {
      return response.data;
     })
     .catch((error) => {
      console.error("请求外部 API 时出错:", error);
      throw error;
     });
   } else if (apiEndpoint.startsWith("/")) {
    // 原始方式處理
    return api
     .get(apiEndpoint)
     .then((response) => response.data)
     .catch((error) => {
      console.error("Error fetching web config:", error);
      throw error;
     });
   } else if (apiEndpoint.startsWith("{")) {
    // 將 apiEndpoint 解析為 JSON 對象
    try {
     const endpoints = JSON.parse(apiEndpoint);
     const promises = Object.entries(endpoints).map(([key, endpoint]) => {
      if (endpoint.startsWith("ws://") || endpoint.startsWith("wss://")) {
       return Promise.resolve(); // 直接返回一个解决的Promise
      }
      if (endpoint.startsWith("http://") || endpoint.startsWith("https://")) {
       // 使用 axios 直接请求，而不使用通过 axios.create 创建的实例
       axios.defaults.withCredentials = true;
       return axios
        .get(endpoint)
        .then((response) => {
         return { [key]: response.data };
        })
        .catch((error) => {
         console.error("请求外部 API 时出错:", error);
         throw error;
        });
      }
      return api.get(endpoint).then((response) => ({ [key]: response.data }));
     });

     // 等待所有請求完成並合併結果
     return Promise.all(promises)
      .then((results) => Object.assign({}, ...results))
      .catch((error) => {
       console.error("Error fetching multiple web configs:", error);
       throw error;
      });
    } catch (error) {
     console.error("Error parsing JSON apiEndpoint:", error);
     throw error;
    }
   }
  },
  initWebsocket(wsURL) {
   if (
    this.$globalVariable.header_ws &&
    this.$globalVariable.header_ws !== null
   )
    return;
   if (!wsURL && this.$globalVariable.header_wsURL)
    wsURL = this.$globalVariable.header_wsURL;
   if (!wsURL) return;
   this.$globalVariable.header_wsURL = wsURL;
   this.$globalVariable.header_ws = new WebSocket(wsURL);
   this.$globalVariable.header_ws.onopen = this.websocketonopen;
   this.$globalVariable.header_ws.onerror = this.websocketonerror;
   this.$globalVariable.header_ws.onmessage = this.websocketonmessage;
   this.$globalVariable.header_ws.onclose = this.websocketclose;
   this.$globalVariable.header_ws_route = this.$route.name;
  },

  waitForConnection(callback, interval) {
   if (
    this.$globalVariable.header_ws &&
    this.$globalVariable.header_ws.readyState === 1
   ) {
    callback();
   } else {
    setTimeout(() => {
     this.waitForConnection(callback, interval);
    }, interval);
   }
  },

  websocketonopen() {
   this.websocketsend("view=" + this.$route.name);
   this.$globalVariable.header_wsTimer = setInterval(() => {
    this.websocketsend("1");
   }, 3000);
  },

  websocketonerror() {
   clearInterval(this.wsTimer);
   this.$globalVariable.header_wsTimer = null;
   this.$globalVariable.header_ws = null;
   setTimeout(this.initWebsocket, 1000);
  },

  websocketonmessage(e) {
   const data = e.data;
   if (data === "OK" || data === "2") return;

   try {
    const jsonData = JSON.parse(data);
    if (!this.$globalVariable.header_ws_response)
     this.$globalVariable.header_ws_response = [];
    this.$globalVariable.header_ws_response.push(jsonData);
    $("#header_websocketonmessage").click();
    this.$globalVariable.ticket++;
   } catch (error) {
    console.error("Error parsing JSON:", error);
   }
  },

  websocketsend(data) {
   if (this.$globalVariable.header_ws) {
    this.$globalVariable.header_ws.send(data);
   }
  },

  websocketclose() {
   clearInterval(this.$globalVariable.header_wsTimer);
   this.$globalVariable.header_wsTimer = null;
   this.$globalVariable.header_ws = null;
   setTimeout(this.initWebsocket, 1000);
  },
  reload() {
   this.res = this.getStorage("res");
   if (!this.res) this.res = {};
   if (
    this.res.templates["headerTemplate"].url &&
    this.res.templates["headerTemplate"].url != ""
   ) {
    this.getCmsList(this.res.templates["headerTemplate"].url).then((res) => {
     this.res = { ...this.res, ...res };
    });
    if (
      this.res.templates["headerTemplate"].url.startsWith("ws://") ||
      this.res.templates["headerTemplate"].url.startsWith("wss://")
    ) {
     this.initWebsocket(this.res.templates["headerTemplate"].url);
    } else if (this.res.templates["headerTemplate"].url.startsWith("{")) {
     const endpoints = JSON.parse(this.res.templates["headerTemplate"].url);
     for (var k in endpoints) {
      if (
       endpoints[k].startsWith("ws://") ||
       endpoints[k].startsWith("wss://")
      ) {
       this.initWebsocket(endpoints[k]);
      }
     }
    }
   }
   $("#header_load_click").click();
  },
 },
 mounted: function () {
  console.log("Header  mounted");
  this.reload();
  this.intervalId = setInterval(() => {
   if (this.ticket != this.$globalVariable.ticket) {
    this.ticket = this.$globalVariable.ticket;
    $("#header_ticket_changed").click();
   }
  }, 250);
 },
};
</script>