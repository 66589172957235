<template>
 <v-runtime-template :template="template"></v-runtime-template>
</template>
<script>
import VRuntimeTemplate from "vue3-runtime-template";
import $ from "jquery";
import axios from "axios";
axios.defaults.withCredentials = true;
export default {
 name: "TopView",
 data() {
  var res = JSON.parse(localStorage.getItem("res"));
  return {
   v: this,
   w: window,
   h: axios,
   template: "",
   template_name: "",
   res: res,
   intervalId: null,
   ticket: null,
  };
 },
 components: {
  VRuntimeTemplate,
 },
 watch: {
  // Watch for route changes
  $route() {
   this.reload();
   console.log("topview_route_reload");
   $("#topview_route_reload").click();
  },
 },
 methods: {
  jQuery(selectort) {
   return $(selectort);
  },
  saveStorage(a, b) {
   this.$globalVariable[a] = b;
   localStorage.setItem(a, JSON.stringify(b));
   this.$globalVariable.ticket++;
  },
  getStorage(a) {
   if (!localStorage.getItem(a)) return this.$globalVariable[a];
   var b = localStorage.getItem(a);
   if (b) b = JSON.parse(b);
   return b;
  },
  reload() {
   var res = JSON.parse(localStorage.getItem("res"));
   var index = res.route.findIndex((x) => x.name == this.$route.name);
   if (
    index > -1 &&
    res.route[index].top &&
    res.route[index].top in res.templates &&
    res.templates[res.route[index].top].content != ""
   ) {
    if (this.template_name != res.route[index].top) {
     this.template_name = res.route[index].top;
     this.template = res.templates[res.route[index].top].content;
     setTimeout(() => {
      console.log("topview_template_changed");
      $("#topview_template_changed").click();
     }, 250);
    }
   }
   else
   {
     this.template_name = "";
     this.template = "";
   }
  },
 },
 mounted: function () {
  this.reload();
  console.log("topview_load");
  $("#topview_load").click();
  this.intervalId = setInterval(() => {
   if (this.ticket != this.$globalVariable.ticket) {
    this.ticket = this.$globalVariable.ticket;
    $("#top_ticket_changed").click();
   }
  }, 250);
 },
};
</script>