import { createApp } from "vue";
import App from "./App.vue";
import axios from "axios";
import { createRouter, createWebHistory, createWebHashHistory } from "vue-router";
import CmsPage from "./views/CmsPage.vue";
import TopView from "./components/TopView.vue";
import BottomView from "./components/BottomView.vue";
import LeftView from "./components/LeftView.vue";
import RightView from "./components/RightView.vue";
import { createI18n } from 'vue-i18n'
axios.defaults.withCredentials = true;
export const api = axios.create({
  baseURL:  window.API_URL || "/api/",
});
window.$ = window.jQuery = require("jquery");
const getWebConfig = () => {
  return api
    .get("cms/web")
    .then((response) => response.data)
    .catch((error) => {
      // Handle errors here, e.g., log or throw an error
      console.error("Error fetching web config:", error);
      throw error;
    });
};

async function addRoute(a) {
  if (Array.isArray(a)) {
    a.forEach((b) => {
      addRoute(b);
    });
  } else {
    a.components = { default: CmsPage, topview: TopView, bottomview: BottomView, leftview: LeftView, rightview: RightView };
  }
}

const numberFormats = {
  'en-US': {
    currency: {
      style: 'currency', currency: 'USD', notation: 'standard'
    },
    decimal: {
      style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2
    },
    percent: {
      style: 'percent', useGrouping: false
    }
  },
  'zh-TW': {
    currency: {
      style: 'currency', currency: 'NTD', notation: 'standard', minimumFractionDigits: 0, maximumFractionDigits: 0
    },
    decimal: {
      style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2
    },
    percent: {
      style: 'percent', useGrouping: false
    }
  },
  'ja-JP': {
    currency: {
      style: 'currency', currency: 'JPY', useGrouping: true, currencyDisplay: 'symbol'
    },
    decimal: {
      style: 'decimal', minimumSignificantDigits: 3, maximumSignificantDigits: 5
    },
    percent: {
      style: 'percent', useGrouping: false
    }
  }
}

getWebConfig().then((res) => {
  localStorage.setItem("res", JSON.stringify(res));
  addRoute(res.route);
  console.log(res.route);
  let router = createRouter({
    history: window.WebHash? createWebHashHistory() : createWebHistory(),
    routes: res.route,
  });

  const i18n = createI18n({
    numberFormats
  })

  const app = createApp(App);
  app.config.globalProperties.$globalVariable = {ticket:0, res: res};
  app.use(router).use(i18n);

  console.log(router.version);
  router.isReady().then(() => {
    // router.replace('/');  // 在路由準備好之後立即導航
    console.log(window.location.pathname);
    document.getElementById('launch').style.display='none';
  });

  app.mount("#app");
});
